
import { Vue } from 'vue-class-component';
import router from '@/router/index'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { auth } from '@/firebase/config'
import { signOut } from 'firebase/auth';
import { readUser } from '@/firebase/firestore/fsServices'


export default class App extends Vue {
  route = useRoute();
  user: any = null;
  path: any;
  nombre: string;
  perfil: number = 0;

  beforeCreate(): void {
    this.path = computed(() => this.route.path);
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        readUser(user.uid).then((data) => {
          this.perfil = data.perfil;
        });
      } else {
        this.user = null;
      }
    });
  }

  toggleNavBar() {
    return this.path.value !== "/login";
  }

  cerrarSesion() {
    signOut(auth).then(() => {
      router.push({ path: "/login" });
    }).catch((error) => {
      // An error happened.
    });
  }


}
