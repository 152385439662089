import { firestore } from "../config"
import { collection, setDoc } from "firebase/firestore";
import { doc, getDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { query, where, orderBy } from "firebase/firestore";

//Tables References
const usersRef = collection(firestore, "usuarios");
const habitacionRef = collection(firestore, "habitaciones");

//Users CRUD and Querys Services
export async function createUser(user: any) {
    let response;
    try {
        const docRef = await setDoc(doc(usersRef, user.uuid), user);
        response = {
            code: 200,
        }
        return response;
    } catch (e) {
        response = {
            code: 500,
            error: e
        }
        return response;
    }
}

export async function readUser(useruid: string) {
    const docRef = doc(firestore, "usuarios", useruid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
}

export async function updateUser(user: any) {
    const updateRef = doc(firestore, "usuarios", user.uuid);
    await updateDoc(updateRef, user).then((response) => { return true; });
}

export async function deleteUser(uid: string) {
    await deleteDoc(doc(firestore, "usuarios", uid)).then((response) => { return true });
}

export async function findAllUsers() {
    const q = query(usersRef, orderBy("user"));
    const querySnapshot = await getDocs(q);
    const usersList: any = [];
    querySnapshot.forEach((doc) => {
        let user = doc.data();
        if(user.user != "root"){
            usersList.push(doc.data());
        }
    });
    return usersList;
}

export async function findUsersByPerfil(perfil: number) {
    const q = query(usersRef, where("perfil", "==", perfil));
    const querySnapshot = await getDocs(q);
    let listUsers: any = [];
    querySnapshot.forEach((doc) => {
        listUsers.push(doc.data());
    });
    return listUsers;
}

//Habitaciones CRUD and Querys Services
export async function createHabitacion(habitacion: any) {
    let response;
    try {
        await setDoc(doc(habitacionRef, habitacion.idHabitacion), habitacion);
        response = {
            code: 200,
        }
        return response;
    } catch (e) {
        response = {
            code: 500,
            error: e
        }
        return response;
    }

}

export async function readHabitacion(uid: string) {
    const docRef = doc(firestore, "habitaciones", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
}

export async function updateHabitacion(habitacion: any) {
    const updateRef = doc(firestore, "habitaciones", habitacion.idHabitacion);
    await updateDoc(updateRef, habitacion).then((response) => { return true; });
}

export async function findAllHabitaciones() {
    const q = query(habitacionRef, orderBy("habitacion", "desc"));
    const querySnapshot = await getDocs(q);
    const habitacionesList: any = [];
    querySnapshot.forEach((doc) => {
        habitacionesList.push(doc.data());
    });
    return habitacionesList;
}
