import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Enfermeria from '../views/EnfermeriaView.vue'
import Nutricion from '../views/NutricionView.vue'
import Recepcion from '../views/RecepcionView.vue'
import Usuarios from '../views/UsersView.vue'
import Register from '../views/RegisterView.vue'
import Login from '../views/LoginView.vue'
import EditUser from '../views/EditUserView.vue'
import Habitaciones from '../views/HabitacionesView.vue'
import EditHabitacion from '../views/EditHabitacionView.vue'
import Direccion from '../views/DirreccionView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/enfermeria',
    name: 'enfermeria',
    component: Enfermeria
  },
  {
    path: '/recepcion',
    name: 'recepcion',
    component: Recepcion
  },
  {
    path: '/nutricion',
    name: 'nutricion',
    component: Nutricion
  },
  {
    path: '/enfermeria',
    name: 'enfermeria',
    component: Enfermeria
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios
  },
  {
    path: '/registro_usuario',
    name: 'registrer',
    component: Register
  },
  {
    path: '/editar_usuario/:uid',
    name: 'editUser',
    component: EditUser
  },
  {
    path: '/habitaciones',
    name: 'habitaciones',
    component: Habitaciones
  },
  {
    path: '/editHabitacion/:uid',
    name: 'editHabitacion',
    component: EditHabitacion
  },
  {
    path: '/direccion',
    name: 'direccion',
    component: Direccion
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
