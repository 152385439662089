<div class="col-md-12">
    <h4 class="text-center">{{habitacion.habitacion}}</h4>
</div>
<form autocomplete="off" @submit.prevent="guardarPaciente">
    <div class="row">
        <p>Información del Paciente Actual en la Habitación:</p>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="nombre" class="form-label">Nombre Completo: *</label>
                <InputText id="nombre" autocomplete="off" type="text" class="vue-input" placeholder="Nombre Completo"
                    v-model="habitacion.nombrePaciente" requiered />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="edad" class="form-label">Edad: *</label>
                <InputNumber id="edad" class="vue-input" v-model="habitacion.edad" inputId="integeronly" suffix=" años"
                    :min="0" :max="100" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="ingre" class="form-label">Fecha de Ingreso: *</label>
                <Calendar id="ingre" class="vue-input" v-model="fecha1"
                dateFormat="dd/mm/yy" showIcon />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="egre" class="form-label">Fecha de Egreso:</label>
                <Calendar id="egre" class="vue-input" v-model="fecha2"
                dateFormat="dd/mm/yy" showIcon/>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="nombreMed" class="form-label">Médico Tratante: *</label>
                <InputText id="nombreMed" autocomplete="off" type="text" class="vue-input" placeholder="Nombre Completo"
                    v-model="habitacion.medicoTratante" requiered />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="nombreEn" class="form-label">Nombre del Enfermero:</label>
                <InputText id="nombreEn" autocomplete="off" type="text" class="vue-input" placeholder="Nombre Completo"
                    v-model="habitacion.nombreEnfermero"/>
            </div>
        </div>

        <div class="col-md-6">
            <div class="mb-3">
                <label for="diagnoi" class="form-label">Diagnóstico Ingreso: *</label>
                <InputText id="diagnoi" autocomplete="off" type="text" class="vue-input" placeholder="Dx."
                    v-model="habitacion.diagnosticoi" requiered />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="diagnoe" class="form-label">Diagnóstico Egreso:</label>
                <InputText id="diagnoe" autocomplete="off" type="text" class="vue-input" placeholder="Dx."
                    v-model="habitacion.diagnosticoe" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="isalergia" class="ml-2 me-2" v-show="habitacion.isalergia">Alergía a Medicamento o Alimento: SI</label>
                <label for="isalergia" class="ml-2 me-2" v-show="!habitacion.isalergia">Alergía a Medicamento o Alimento: NO</label>
                <Checkbox id="isalergia" v-model="habitacion.isalergia" :binary="true" />
            </div>
            <div class="mb-3" v-if="habitacion.isalergia">
                <label for="alergia" class="form-label">¿Cúales? </label>
                <Chips separator="," v-model="habitacion.alergias" class="vue-input"/>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label for="dia" class="ml-2 me-2" v-show="habitacion.isdiabetico">Diabético: SI</label>
                <label for="dia" class="ml-2 me-2" v-show="!habitacion.isdiabetico">Diabético: NO</label>
                <Checkbox v-model="habitacion.isdiabetico" :binary="true" />
            </div>
            <div class="mb-3">
                <label for="hiper" class="ml-2 me-2" v-show="habitacion.ishipertenso">Hipertenso: SI</label>
                <label for="hiper" class="ml-2 me-2" v-show="!habitacion.ishipertenso">Hipertenso: NO</label>
                <Checkbox v-model="habitacion.ishipertenso" :binary="true" />
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-12 text-center">
            <Button type="submit" label="Actualizar Habitación" severity="success" :loading="loader" icon="pi pi-save">
            </button>
        </div>
    </div>
</form>