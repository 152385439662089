import { readHabitacion, updateHabitacion } from '@/firebase/firestore/fsServices';
import { useToast } from "primevue/usetoast";
import { Component, Vue } from 'vue-facing-decorator';
import { useRoute } from 'vue-router';
import router from '@/router/index'

@Component
export default class EditHabitacion extends Vue {
    route = useRoute();
    toast = useToast();

    //local variables
    uid?: any;
    habitacion?: any = {};
    sexos: any[];
    loader: false;
    error: string;
    fecha1: Date;
    fecha2: Date;

    created(): void {
        this.uid = this.route.params.uid;
        readHabitacion(this.uid).then((data) => {
            console.log(data);
            this.habitacion = data
            if (data.fechaing) {
                this.fecha1 = new Date(data.fechaing);
            }
            if (data.fechaegr) {
                this.fecha2 = new Date(data.fechaegr);
            }
        });
    }

    mounted(): void {
        this.sexos = [
            { id: 1, valor: "Masculino" },
            { id: 2, valor: "Femenino" },
        ];
    }

    guardarPaciente() {
        if (this.validatePaciente()) {
            this.habitacion.status = 1;
            this.formatFechas();
            updateHabitacion(this.habitacion).then((data) => {
                router.push({ path: "/habitaciones" });
                this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se actualizó la información del paciente', life: 3600 });
            });
        } else {
            this.toast.add({ severity: 'warn', summary: 'Validación', detail: this.error, life: 3600 });
        }
    }

    private formatFechas() {
        this.habitacion.fechaing = this.fecha1.getTime();
        if (this.habitacion.fechaegr) {
            this.habitacion.fechaegr = this.fecha2.getTime();
        }
    }


    private validatePaciente() {
        if (this.habitacion.nombrePaciente == null) {
            this.error = "El nombre del paciente no puede estar vacío";
            return false;
        }
        if (this.habitacion.edad == null) {
            this.error = "La edad no puede estar vacía";
            return false;
        }
        if (this.fecha1 == null) {
            this.error = "La fecha de ingreso no puede estar vacía";
            return false;
        }
        if (this.habitacion.diagnosticoi == null) {
            this.error = "El Diagnóstico iniciar no puede esta vacío";
            return false;
        }
        if (this.habitacion.medicoTratante == null) {
            this.error = "El nombre del médico no puede estar vacío";
            return false;
        }
        if (this.habitacion.isalergia && this.habitacion.alergias == null) {
            return false;
        }
        return true;
    }

}