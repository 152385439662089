import { database } from '../config'
import { ref, push, set } from "firebase/database";

//References to database tables
const mensajesRef = ref(database, 'mensajes');

//create new chat by user
export function createUserChat(uidchat: string, username: string) {
    const primerMensaje = {
        data: "Bienvenido al chat de la " + username,
        prioridad: 1,
        remitente: "General",
        status: 1,
        timestamp: new Date().getTime(),
        tipo: "text"
    }
    push(ref(database, 'mensajes/' + uidchat), primerMensaje);
}

//CRUD Functions to references
export function saveMenssage(mensaje: any, uidchat: string) {
    push(ref(database, 'mensajes/' + uidchat), mensaje);
}

export async function deleteMessages(uidchat: string) {
    set(ref(database, 'mensajes/' + uidchat), null).then((data) => {
        return true;
    }).catch((err) => {
        return false;
    });
}
