import { Component, Vue } from 'vue-facing-decorator'
import router from '@/router/index'
import { findAllHabitaciones } from "@/firebase/firestore/fsServices"
import { updateHabitacion } from '@/firebase/firestore/fsServices'
import { deleteMessages } from '@/firebase/realtimeDB/services'
import { useToast } from "primevue/usetoast";
import Swal from 'sweetalert2'

@Component
export default class Habitaciones extends Vue {
    habitaciones?: any[];
    toast = useToast();

    mounted(): void {
        findAllHabitaciones().then((data) => {
            this.habitaciones = data;
        });
    }

    formatHabitacion(tipo: number) {
        switch (tipo) {
            case 0:
                return "Libre"
            case 1:
                return "Ocupada"
            default:
                break;
        }
    }

    editHabitacion(uid: string) {
        router.push('/editHabitacion/' + uid);
    }

    liberarHabitacion(habitacion: any) {
        Swal.fire({
            title: '¿Desea liberar la: ' + habitacion.habitacion + '?',
            text: 'Esto limpiará la información del paciente',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                const reset = this.resetHabitacion(habitacion);
                updateHabitacion(reset).then(() => {
                    deleteMessages(habitacion.idHabitacion).then((data) => {
                        this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'La habitación se liberó correctamente', life: 3600 });
                    });
                });
            }
        });
    }


    private resetHabitacion(habitacion: any) {
        habitacion.nombrePaciente = null;
        habitacion.edad = null;
        habitacion.diagnosticoi = null;
        habitacion.diagnosticoe = null;
        habitacion.nombreEnfermero = null;
        habitacion.medicoTratante = null;
        habitacion.isalergia = false;
        habitacion.alergias = [];
        habitacion.isdiabetico = false;
        habitacion.ishipertenso = false;
        habitacion.fechaing = null;
        habitacion.fechaegr = null;
        habitacion.status = 0;
        return habitacion;
    }
}